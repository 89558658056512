"use client";

import React, { useState } from "react";
import CMSLink from "@/cms/links/components/CMSLink";
import { PiCaretLeft, PiCaretRight } from "react-icons/pi";
import { LinkGroup } from "./headertypes";

type LinkGroupSideMenuProps = {
  linkGroup: LinkGroup[];
};

const LinkGroupSideMenu = ({ linkGroup }: LinkGroupSideMenuProps) => {
  const [layerHistory, setLayerHistory] = useState([linkGroup]);
  const [titleArray, setTitleArray] = useState(["Meny"]);

  const addToLayerHistory = (pagePath: LinkGroup[]) => {
    setLayerHistory([...layerHistory, pagePath]);
  };
  const removeCurrentLayer = () => {
    const newCurrentLayer = layerHistory.slice(0, -1);
    setLayerHistory(newCurrentLayer);
  };

  const addToTitleArray = (title: string) => {
    setTitleArray([...titleArray, title]);
  };

  const removeTitleArray = () => {
    const NewTitleArray = titleArray.slice(0, -1);
    setTitleArray(NewTitleArray);
  };

  const currentLayer = layerHistory[layerHistory.length - 1];

  return (
    <div className="mt-3 flex flex-col gap-2 px-6">
      {layerHistory.length >= 2 && (
        <span
          className="absolute top-10 left-3 flex h-[26px] cursor-pointer items-center gap-1 p-4 font-semibold"
          onClick={() => {
            removeCurrentLayer();
            removeTitleArray();
          }}
        >
          {layerHistory.length == 2 && (
            <>
              <PiCaretLeft /> Tilbake til Meny
            </>
          )}
          {layerHistory.length >= 3 && (
            <>
              <PiCaretLeft /> Tilbake til {titleArray[layerHistory.length - 2]}
            </>
          )}
        </span>
      )}
      <h2 className="text-heading02 min-h-[27px] font-semibold">
        {layerHistory.length == 1 && "Meny"}
        {layerHistory.length != 1 && titleArray[layerHistory.length - 1]}
      </h2>
      {layerHistory && (
        <ul>
          {currentLayer?.map((linkGroup: LinkGroup, index: number) => {
            if (linkGroup._type === "linkConfig") {
              return (
                <li
                  key={linkGroup._key}
                  className="flex w-full border-b-2 border-[#CACBCE] py-6"
                >
                  <CMSLink
                    className="flex flex-row-reverse gap-2"
                    data={{
                      ...linkGroup,
                      variant: "custom",
                      contentAlignment: "left",
                    }}
                  />
                </li>
              );
            }
            return (
              <li
                key={linkGroup._key}
                className="flex w-full gap-[auto] border-b-2 border-[#CACBCE] py-6"
                onClick={() => {
                  addToLayerHistory(currentLayer?.[index]?.linkGroup || []);
                  addToTitleArray(currentLayer?.[index].title || "");
                }}
              >
                <button>{linkGroup.title}</button>
                <PiCaretRight size={24} className="ml-[auto]" />
              </li>
            );
          })}
        </ul>
      )}
    </div>
  );
};
export default LinkGroupSideMenu;
