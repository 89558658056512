"use client";

import {
  SearchBar as SearchComponent,
  getBookSource,
  getSubscriptionSource,
} from "@cappelendamm/product-search";
import {
  searchClient,
  bookIndex,
  querySuggestionsIndex,
  subscriptionIndex,
} from "./client";

export const SearchBar = () => {
  return (
    <SearchComponent
      searchClient={searchClient}
      querySuggestionsIndex={querySuggestionsIndex}
      querySources={({ query }) => [
        getSubscriptionSource({
          query,
          indexName: subscriptionIndex,
          searchClient,
        }),
        getBookSource({ query, indexName: bookIndex, searchClient }),
      ]}
    />
  );
};
